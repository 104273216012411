<template>
  <div class="contents-section">
    <div class="title-section">
      <h2>내 정보</h2>
      <v-btn
        depressed
        color="primary"
        @click="logOutFunc"
      >
        로그아웃
      </v-btn>
    </div>
    <div v-if="userInfo !== null">
      <div class="ver">
        <div class="val key">
          <p class="text-label">아이디</p>
        </div>
        <div class="val">{{ userInfo.id }}</div>
      </div>
      <div class="ver">
        <div class="val key">
          <p class="text-label">이름</p>
        </div>
        <div class="val">{{ userInfo.name }}</div>
      </div>
      <div class="ver">
        <div class="val key">
          <p class="text-label">그룹</p>
        </div>
        <div class="val">{{ groupName?groupName:'' }}</div>
      </div>
      <div class="ver">
        <div class="val key">
          <p class="text-label">직위/직책</p>
        </div>
        <div class="val">{{ userInfo.position?userInfo.position:'없음' }}</div>
      </div>
    </div>
    <div class="ver mt-4">
      <div class="val">
        <v-btn depressed color="error" @click="dialog = true">비밀번호 변경</v-btn>
      </div>
    </div>
    <dlg-password-cng title="비밀번호 변경" :dialog.sync="dialog"></dlg-password-cng>
  </div>
</template>

<script>
import DlgPasswordCng from '@/components/dialog/DlgPasswordCng.vue';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import comm from '@/util/comm';

export default {
  name: 'MyPage',
  components: { DlgPasswordCng },
  computed: {
    ...mapGetters({
      companyInfo: 'auth/companyInfo',
      userInfo: 'auth/userInfo',
      verified: 'auth/verified',
    }),
  },
  data: () => ({
    comm,
    dialog: false,
    groupName: null,
  }),
  methods: {
    ...mapMutations({
      verifiedCng: 'auth/verified',
    }),
    ...mapActions({
      logout: 'auth/logout',
    }),
    logOutFunc() {
      this.logout();
    },
    getGroupList() {
      this.groupLoad = false;
      this.$socket.emit('groups.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['no'],
        sortDesc: [false],
        filters: [],
      }, (resp) => {
        if (resp.result === 'success') {
          this.groupName = this.comm.getGroupFullPath(resp.items.groups, this.userInfo.groupNo, '그룹없음');
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(val) {
        if (val !== null) this.getGroupList();
      },
    },
  },
};
</script>

<style scoped lang="scss">
.ver{
  display: flex;
  .val{
    padding:10px;
    width:500px;
    &.key {
      width:140px;
      flex-grow: 0;
    }
  }
}
</style>
