<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="400px"
      @click:outside="pwCngInit()"
      @keydown="$event.key === 'Escape' ? pwCngInit() : ''"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5 mt-2">{{ title }}</span>
        </v-card-title>
        <v-card-text v-if="!validChecked">
          <p class="mt-2 mb-6">본인확인을 위해 기존 비밀번호를 입력해주세요.</p>
          <v-text-field
            autofocus
            label="기존비밀번호"
            outlined
            color="black"
            v-model="oldPw"
            :rules="oldPw_rules"
            @keydown="$event.key === 'Enter'?oldPwCheckFunc():''"
            type="password"
          ></v-text-field>
        </v-card-text>
        <v-card-text v-if="validChecked">
          <p class="mt-2 mb-6">새로 사용할 비밀번호를 입력해주세요.<br>8자~16자의 영문자, 숫자를 조합하여 입력바랍니다.</p>
          <v-text-field
            autofocus
            label="신규비밀번호"
            outlined
            v-model="newPw"
            :rules="newPw_rules"
            type="password"
            color="black"
            @keyup="newPwCheckFunc($event)"
          ></v-text-field>
          <v-text-field
            label="신규비밀번호확인"
            outlined
            v-model="newPwCheck"
            :rules="newPwCheck_rules"
            type="password"
            color="black"
            @keyup="newPwCheckFunc($event)"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="pb-6 pr-6 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            v-if="!validChecked"
            color="primary"
            depressed
            @click="oldPwCheckFunc()"
          >
            다음
          </v-btn>
          <v-btn
            v-if="validChecked"
            color="primary"
            depressed
            @click="newPwRegistFunc()"
          >
            변경
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'DlgPasswordCng',
  props: {
    title: {
      type: String,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    validChecked: false,
    oldPw: '',
    oldPw_rules: [true],
    newPw: '',
    newPw_rules: [true],
    newPwCheck: '',
    newPwCheck_rules: [true],
  }),
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
    }),
  },
  methods: {
    ...mapMutations({
      progress: 'dialog/progress',
    }),
    ...mapActions({
      alert: 'dialog/alert',
      logout: 'auth/logout',
    }),
    pwCngInit() {
      this.validChecked = false;
      this.oldPw = '';
      this.oldPw_rules = [true];
      this.newPw = '';
      this.newPw_rules = [true];
      this.newPwCheck = '';
      this.newPwCheck_rules = [true];
      this.$emit('update:dialog', false);
    },
    oldPwCheckFunc() {
      if (this.oldPw === '') {
        this.oldPw_rules = [true];
        this.alert(['error', '비밀번호를 입력해주세요.']);
      } else {
        this.progress(true);
        this.$socket.emit('users.token.get', {
          id: this.userInfo.id,
          password: this.oldPw,
        }, (resp) => {
          if (resp.result === 'success') {
            this.oldPw_rules = [true];
            this.validChecked = true;
            this.progress(false);
          } else {
            this.oldPw_rules = ['비밀번호가 일치하지 않습니다.'];
            this.progress(false);
          }
        });
      }
    },
    newPwCheckFunc(e) {
      const reg = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,16}$/;
      const checked = reg.test(this.newPw);
      if (!checked) {
        this.newPw_rules = ['8자~16자의 영문자, 숫자를 조합하여 입력해주세요.'];
      } else {
        this.newPw_rules = [true];
      }

      if (this.newPwCheck === '' || this.newPw !== this.newPwCheck) {
        this.newPwCheck_rules = ['비밀번호가 일치하지 않습니다.'];
      } else {
        this.newPwCheck_rules = [true];
      }
      if (e.key === 'Enter') {
        this.newPwRegistFunc();
      }
    },
    newPwRegistFunc() {
      const valid = this.newPw !== '' && this.newPw === this.newPwCheck ? true : '';
      if (valid === true && this.newPw_rules[0] === true && this.newPwCheck_rules[0] === true) {
        this.progress('true');
        this.$socket.emit('users.reset', {
          oldPassword: this.oldPw,
          newPassword: this.newPw,
        }, (resp) => {
          if (resp.result === 'success') {
            this.logout();
            this.alert(['success', '비밀번호가 변경되었습니다. 다시 로그인해주세요.']);
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          }
          this.pwCngInit();
          this.progress('false');
        });
      }
    },
  },
  mounted() {
  },
};
</script>

<style scoped lang="scss">

</style>
